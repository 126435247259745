import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  MembershipServiceInterface,
  ModalHelperService,
  StorageService,
  TimeService,
  UserServiceInterface,
} from 'common';
import { MembershipService } from 'src/app/service/membership.service';
import { environment } from "../../environments/environment";
import appVersion from '../../../app-version.json';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              @Inject('MembershipService') private membershipService: MembershipServiceInterface,
              @Inject('UserService') private userService: UserServiceInterface,
              private translateService: TranslateService,
              private storageService: StorageService,
              public modalHelperService: ModalHelperService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only for internal apps
    if (!req.url.startsWith(environment.serverUrl) && !req.url.startsWith(environment.websocketUrl)) {
      return next.handle(req);
    }
    // add authorization header
    // Apply the headers
    const headers = {
      Source: 'web',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Access-Control-Allow-Origin': '*',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: '',
      'App-Version': appVersion.version,
      factoryId: '',
    };
    return this.authService.getJwt()
      .pipe(
        tap((idToken: string) => {
          if (idToken) {headers.Authorization = 'Bearer ' + idToken;}
        }),
        tap(() => {
          if (this.storageService.getItem(MembershipService.lastSelectedFactoryIdKey)) {
            headers.factoryId = this.storageService.getItem(MembershipService.lastSelectedFactoryIdKey);
          }
        }),
        // add headers into request
        tap(() => {
          req = req.clone({
            setHeaders: headers
          });
        }),
        mergeMap(() => next.handle(req)),
        tap(x => x, err => {
          // convert error to object if required
          if (err instanceof HttpErrorResponse) {
            this.managerError(err);
          }
          if (typeof err.error === 'string') {
            err.error = JSON.parse(err.error);
          }
          if (err.error?.message) {
            err.message = err.error.message;
          }
          console.info(`Error performing request, status code = ${err.status}`);
        })
      );
  }

  private managerError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.userService.hasMyself()
        .pipe(
          filter(hasMyself => hasMyself),
          tap(() => this.storageService.cleanAll()),
          tap(() => this.membershipService.removeSelectedFactory()),
        ).subscribe(() => {
        // unauthorized : log out the user
        this.authService.signOut()
          .pipe(tap(() => window.location.reload()))
          .subscribe();
      });
    } else if (error.status === 417) {
      // expectation failed
      let errorMessage: string = undefined;
      if (typeof error?.error === 'string' || error?.error instanceof String) {
        const err = JSON.parse(error.error as string);
        errorMessage = err.message;
      } else if (error?.error?.message) {
        errorMessage = error.error.message;
      }
      if (errorMessage) {
        this.modalHelperService.displayToaster(errorMessage, 4000);
      } else {
        this.modalHelperService.displayToaster(this.translateService.instant('network.expectationFailedUnknown', {
          time: TimeService.format(TimeService.getNowUtc().toISOString(), 'HH:mm:ss'),
        }), 4000);
      }
    } else if (error.status >= 500) {
      this.modalHelperService.displayToaster(this.translateService.instant('network.unknownError'), 4000);
    } else {
      this.modalHelperService.displayToaster(this.translateService.instant('network.unknownError'), 4000);
    }
  }
}
