import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService, UserApiService } from "common";
import { Observable, pipe, UnaryFunction } from "rxjs";
import { Router } from "@angular/router";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { UserService } from "../../service/user.service";

@Component({
  standalone: false,
  selector: 'app-page-remove-account',
  templateUrl: 'remove-account.page.html',
  styleUrls: ['remove-account.page.scss'],
})
export class RemoveAccountPage {
  success = false;
  loading = false;
  form: FormGroup;
  isLogged$: Observable<boolean>;
  submissionPipe: UnaryFunction<Observable<{
    email: string;
    password: string
  }>, Observable<any>> = pipe(
    mergeMap((body: {
      email: string;
      password: string
    }) => this.authService.login(body.email, body.password)),
    mergeMap(() => this.userService.refreshUser()),
    tap(() => this.isLogged$ = this.authService.isLogged()),
  );

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private userApiService: UserApiService,
              private userService: UserService,
  ) {
    this.form = this.formBuilder.group({
      anonymousUser: [false],
      validationText: ['', [Validators.required, Validators.pattern('^delete$')]],
    });
    this.isLogged$ = this.authService.isLogged();
  }


  getFormController(fieldName: string): FormControl {
    return this.form?.get(fieldName) as FormControl;
  }

  back() {
    this.router.navigate(['']);
  }

  submit() {
    this.loading = true;
    this.userApiService.removeUser({
      body: this.form.value,
    })
      .pipe(
        mergeMap(() => this.authService.signOut()),
        catchError((err) => {
          this.loading = false;
          throw err;
        })
      )
      .subscribe(() => {
        this.loading = false;
        this.success = true;
      });
  }
}
